import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
const route = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomePage.vue'),
    meta: {
      title: '首页',
      keepAlive: true
    }
  }
]
export default new Router({
  routes: route,
  mode: 'hash'
})