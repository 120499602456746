<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
input {
  border: none;
}

input:focus-visible {
  outline: none;
}

button {
  border: none;
  color: #ffffff;
  padding: 10px 20px;
  background-color: #007aff;
}

button:focus-visible {
  outline: none;
}
</style>
