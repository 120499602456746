import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/index.scss'
import "@/assets/css/index1.scss"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.directive('removeAriaHidden', {
  bind(el) {
    const ariaEls = el.querySelectorAll('.el-radio__original')
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden')
    })
  }
})
Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
